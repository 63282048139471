import * as React from "react";

//import "../assets/plugins/nucleo/css/nucleo.css";
//import "@fortawesome/fontawesome-free/css/all.min.css";
import "../assets/scss/argon-dashboard-react.scss";
import PrimaryNavbar from "../components/Navbars/PrimaryNavbar";

import { Button, Col, Container, Row, Badge, CardBody, Card } from "reactstrap";
import { Helmet } from "react-helmet";

import appleIcon from "../assets/img/daves/apple-touch-icon.png";
import fav16 from "../assets/img/daves/favicon-16x16.png";
import fav32 from "../assets/img/daves/favicon-32x32.png";
import safariPinned from "../assets/img/daves/safari-pinned-tab.svg";
import _ from "underscore";

/*if (window.location.href.indexOf("localhost") >= 0) {
  accountManagerPath = "http://localhost:3000";
}*/

let everyplate = require("../assets/img/daves/everyplate_logo.png");
let greenchef = require("../assets/img/daves/greenchef_logo.png");
let hellofresh = require("../assets/img/daves/hellofresh_logo.png");
let facebook = require("../assets/img/daves/facebook_logo.png");
let instagram = require("../assets/img/daves/ig_logo.jpeg");
let tiktok = require("../assets/img/daves/tiktok_logo.png");
let squatch = require("../assets/img/daves/dr_squatch_logo.png");
let googleReviews = require("../assets/img/daves/google_review_logo.svg");

let banner1 = require("../assets/img/daves/daves_banner_1_desktop.jpg");
let banner2 = require("../assets/img/daves/daves_banner_2_desktop.jpg");
let banner3 = require("../assets/img/daves/daves_banner_3_desktop.jpg");

// markup
class IndexPage extends React.Component {
  state = {
    email: "",
    zip: "",
    leadSource: "",
    isDesktop: false,
    phone: {
      value: "",
    },
    ffRequirePhone: true,
    review: {
      review:
        "I'm so thankful for Steve, the owner of Dave's Automotive! It's so nice to know I can send my wife and family there, and they are so kind and friendly and helpful.  We're very happy with the level of customer service and value we get there!",
      image: require("../assets/img/daves/jp_google.png"),
      name: "JP P.",
    },
    portfolioIndex: 0,
    reviews: [
      {
        review:
          "I'm so thankful for Steve, the owner of Dave's Automotive! It's so nice to know I can send my wife and family there, and they are so kind and friendly and helpful.  We're very happy with the level of customer service and value we get there!",
        image: require("../assets/img/daves/jp_google.png"),
        name: "JP P.",
      },
      {
        review:
          "Steve and his crew are the best. I have been using them for many years. Always professional and fair. I take my everyday vehicles as well as my classic car here and they always take care of me first rate. I would highly recommend.",
        image: require("../assets/img/daves/brians_google.png"),
        name: "Brian S.",
      },
      {
        review:
          "Thank you to Steve and the crew for all the work they have done to my car. Everyone at Dave's Automotive has been friendly and extremely helpful every time I take my car in. Thanks again!",
        image: require("../assets/img/daves/corbind_google.png"),
        name: "Corbin D.",
      },
      {
        review:
          "My car needed new brakes. Steve was very professional and after consulting me with the car's issues. Front brakes were replaced. I will definitely go back to Dave's.",
        image: require("../assets/img/daves/joycek_google.png"),
        name: "Joyce K.",
      },
      {
        review:
          "Finding a quality/reasonable/trust-worthy mechanic is like trying to find a family physician or a vet. We put our trust in Steve and his guys with all our family cars. We are always confident our cars are in good shape and safe when we leave. Thanks Steve for taking care of our cars and family.",
        image: require("../assets/img/daves/danielles_google.png"),
        name: "Danielle S.",
      },
      {
        review:
          "Steve cares about his clients and serves them well. Dave’s Automotive is a very honest and reliable shop. They do great work. The entire crew are wonderful to work with.",
        image: require("../assets/img/daves/kims_google.png"),
        name: "Kim S.",
      },
      {
        review:
          "Dave's Automotive has repaired my vehicle on numerous occasions, and each experience has been positive. They have given me honest assessments of any issues with my vehicle, and each repair has been done right and performed in a timely fashion. ",
        image: require("../assets/img/daves/mattd_google.png"),
        name: "Matt D.",
      },
    ],
    banner: {
      id: 1,
      header: "Professional Auto & Truck Repair",
      subheader:
        "Providing quality repairs at reasonable rates with guaranteed customer satisfaction using the latest vehicle service procedures & maintenance standards.",
      styles: {
        background: `url(${banner1}) center center / cover`,
      },
    },

    banners: [
      {
        id: 1,
        header: "Professional Auto & Truck Repair",
        subheader:
          "Providing quality repairs at reasonable rates with guaranteed customer satisfaction using the latest vehicle service procedures & maintenance standards.",

        styles: {
          background: `url(${banner1}) center center / cover`,
        },
      },
      {
        id: 2,
        header: "Reliable, Quality Service Since 1984",
        subheader:
          "Dave’s Automotive provides vehicle repairs & maintenance so you can have safe, reliable transportation with peace of mind.",
        styles: {
          background: `url(${banner2}) center center / cover`,
        },
      },
      {
        id: 3,
        header: "Using The Latest Technology To Service Your Vehicles",
        subheader:
          "Our technicians use the latest technologies & standards to provide you fast, reliable service.",
        styles: {
          background: `url(${banner3}) center center / cover`,
        },
      },
    ],
  };

  handleResize(mq) {
    let isDesktop = mq && mq.matches ? true : false;

    this.setState({ isDesktop });
  }

  componentWillUnmount() {}

  setPortfolioIndex(increment) {
    let idx = this.state.portfolioIndex;

    if (idx + increment < 0) {
      idx = this.state.reviews?.length - 1;
    } else if (idx + increment > this.state.reviews?.length - 1) {
      idx = 0;
    } else {
      idx = idx + increment;
    }

    this.setState({
      portfolioIndex: idx,
      review: this.state.reviews[idx],
    });
  }

  componentDidMount() {
    let scrollpos = window.scrollY;
    const header = document.querySelector("body");
    const header_height = 20;

    const add_class_on_scroll = () => header.classList.add("body-scroll");
    const remove_class_on_scroll = () => header.classList.remove("body-scroll");

    this.setState({
      review: this.state.reviews[0],
    });

    window.addEventListener("scroll", function () {
      scrollpos = window.scrollY;

      if (scrollpos >= header_height) {
        add_class_on_scroll();
      } else {
        remove_class_on_scroll();
      }
    });

    setInterval(() => {
      let id = this.state.banner?.id ? this.state.banner?.id : 1;

      if (id == 3) {
        id = 1;
      } else {
        id += 1;
      }

      const selected = _.findWhere(this.state.banners, { id });

      this.setState({ banner: selected });
    }, 12000);
  }

  renderVideo(portfolio) {
    if (!portfolio) {
      return null;
    }

    if (portfolio?.type == "video" && portfolio?.url) {
      return (
        <video
          preload="metadata"
          width={"100%"}
          key={portfolio?.url}
          style={{ width: "100%" }}
          height="400"
          controls
        >
          <source src={portfolio?.url + "#t=0.1"} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      );
    }
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Dave's Automotive - Professional Auto & Truck Repair</title>
          <meta
            name="description"
            content="Dave’s always provides quality vehicle repairs at reasonable rates with guaranteed customer satisfaction. Our automotive technicians are constantly being retrained and updated on the latest vehicle service procedures and maintenance requirements."
          ></meta>
          <meta property="og:locale" content="en_US"></meta>
          <meta property="og:type" content="website"></meta>
          <meta
            property="og:title"
            content="Dave's Automotive - Professional Auto & Truck Repair"
          ></meta>
          <meta property="og:description" content=""></meta>
          <meta
            property="og:url"
            content="https://davesautomotiveorchardpark.com/"
          ></meta>
          <meta property="og:site_name" content="Dave's Automotive"></meta>

          <meta property="og:image:width" content="1024"></meta>
          <meta property="og:image:height" content="768"></meta>
          <link rel="apple-touch-icon" sizes="180x180" href={appleIcon} />
          <link rel="icon" type="image/png" sizes="32x32" href={fav32} />
          <link rel="icon" type="image/png" sizes="16x16" href={fav16} />
          <link rel="mask-icon" href={safariPinned} color="#38b6cd" />
          <meta name="msapplication-TileColor" content="#ffffff" />
          <meta name="theme-color" content="#ffffff"></meta>
        </Helmet>
        <PrimaryNavbar></PrimaryNavbar>
        <div className="mt-5 mt-lg-6" id="home"></div>
        <div className="bg-white" style={{ maxWidth: 1100, margin: "auto" }}>
          <div className="py-3 py-md-4 py-lg-5 px-1 px-md-4 px-lg-5 mt-lg-5 hero-container bg-dark d-flex align-items-center">
            {this.state.banners?.map((banner, i) => (
              <div
                key={banner?.id}
                className={`hero-image-container ${
                  this.state.banner?.id == banner?.id ? "hero-active" : ""
                }`}
                style={banner?.styles}
              ></div>
            ))}
            <div
              style={{ width: "100%" }}
              className="py-5 py-sm-6 py-md-7 px-3 px-md-0"
            >
              <Row style={{ alignSelf: "center" }}>
                <Col xs="12" sm="10" md="7" lg="6">
                  <h1 className="display-2 text-white">
                    {this.state.banner?.header}
                  </h1>
                  <p className="lead m-0 text-white">
                    {this.state.banner?.subheader}
                  </p>
                  <a href="tel:+17166621467">
                    <Button className="mt-4" size="" color="primary">
                      Schedule Appointment
                    </Button>
                  </a>
                </Col>
              </Row>
            </div>
          </div>
          <div id="autorepair"></div>
          <div className="py-5 py-md-6 py-lg-6 px-1 px-lg-4 mx-lg-2">
            <Container fluid>
              <div className="">
                <Row className="align-items-center">
                  <Col xs="12" sm="12" lg="12">
                    <Row className="align-items-center mb-2 mb-md-4">
                      <Col xs="12" sm="">
                        <h1
                          className="display-2 text-capitalize text-dark text-left m-0"
                          style={{ lineHeight: 1.2 }}
                        >
                          One Shop.{" "}
                          <span className="text-primary d-inline font-weight-700">
                            Full Service.
                          </span>
                        </h1>
                        <p className="lead">
                          With over 50 years of combined automotive expertise,
                          let Dave’s Automotive take your service experience to
                          a new level by giving you service you can count on!
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ display: "flex", flexWrap: "wrap" }}>
                      <Col xs="12" sm="12" md="4" className="mb-3 mb-md-0">
                        <div className="border rounded shadow-sm bg-white px-4 py-4 h-100">
                          <Row className="align-items-center">
                            <Col
                              xs="auto"
                              md="12"
                              className="mb-0 text-center mb-md-4"
                            >
                              <img
                                src={require("../assets/img/daves/general.svg")}
                                height="60"
                                width="60"
                                alt="general maintenance"
                                style={{ height: 60 }}
                              ></img>
                            </Col>
                            <Col
                              xs=""
                              md="12"
                              className="text-left text-md-center"
                            >
                              <h2 className="text-dark">General Maintenance</h2>
                              <p
                                className="m-0 text-muted"
                                style={{ lineHeight: 1.4, fontSize: 14 }}
                              >
                                One-time and routine service that keeps your
                                vehicle running smoothly and safely.
                              </p>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                      <Col xs="12" sm="12" md="4" className="mb-3 mb-md-0">
                        <div className="border rounded shadow-sm bg-white px-4 py-4 h-100">
                          <Row className="align-items-center">
                            <Col
                              xs="auto"
                              md="12"
                              className="mb-0 text-center mb-md-4"
                            >
                              <img
                                src={require("../assets/img/daves/computer.svg")}
                                height="60"
                                width="60"
                                alt="computerized diagnostics"
                                style={{ height: 60 }}
                              ></img>
                            </Col>
                            <Col
                              xs=""
                              md="12"
                              className="text-left text-md-center"
                            >
                              <h2 className="text-dark">
                                Computerized Diagnosis
                              </h2>
                              <p
                                className="m-0 text-muted"
                                style={{ lineHeight: 1.4, fontSize: 14 }}
                              >
                                Detailed information about your car's
                                performance and any problems it may be
                                experiencing.
                              </p>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                      <Col xs="12" sm="12" md="4">
                        <div className="border rounded shadow-sm bg-white px-4 py-4 h-100">
                          <Row className="align-items-center">
                            <Col
                              xs="auto"
                              md="12"
                              className="mb-0 text-center mb-md-4"
                            >
                              <img
                                src={require("../assets/img/daves/tire.svg")}
                                height="60"
                                width="60"
                                alt="tire & oil"
                                style={{ height: 60 }}
                              ></img>
                            </Col>
                            <Col
                              xs=""
                              md="12"
                              className="text-left text-md-center"
                            >
                              <h2 className="text-dark">Oil & Tire Changes</h2>
                              <p
                                className="m-0 text-muted"
                                style={{ lineHeight: 1.4, fontSize: 14 }}
                              >
                                Full multi-point vehicle inspection with your
                                oil & filter change. Tire change, alignment &
                                rotation services.
                              </p>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>

                    <div className="mt-6">
                      <h1 className="text-dark mb-4 display-4">
                        Maintenance That Meets Your Needs For All Major Car
                        Brands
                      </h1>
                      <p className="text-muted">
                        Whether your vehicle is gas, diesel, or hybrid and just
                        needs a tune-up or a major over-haul, Dave’s Automotive
                        is ready to give you quality, professional maintenance
                        service and friendly customer service at fair and
                        affordable prices.
                      </p>
                      <p className="text-muted mb-4">
                        Our highly-trained, professional technicians employ the
                        latest in automotive technology and are equipped to
                        handle all major and minor auto repair services on
                        foreign and domestic vehicles.
                      </p>
                    </div>
                    <div className="mt-4">
                      <Badge
                        color="primary"
                        className="rounded bg-primary-ultralight mr-3 mb-3"
                        style={{
                          fontSize: 14,
                          color: "var(--dark)",
                          border: "1px solid var(--primary)",
                        }}
                      >
                        General Maintenance
                      </Badge>
                      <Badge
                        color="primary"
                        className="rounded bg-primary-ultralight mr-3 mb-3"
                        style={{
                          fontSize: 14,
                          color: "var(--dark)",
                          border: "1px solid var(--primary)",
                        }}
                      >
                        Oil &amp; Oil Filter Service
                      </Badge>
                      <Badge
                        color="primary"
                        className="rounded bg-primary-ultralight mr-3 mb-3"
                        style={{
                          fontSize: 14,
                          color: "var(--dark)",
                          border: "1px solid var(--primary)",
                        }}
                      >
                        Tire Change &amp; Alignment
                      </Badge>
                      <Badge
                        color="primary"
                        className="rounded bg-primary-ultralight mr-3 mb-3"
                        style={{
                          fontSize: 14,
                          color: "var(--dark)",
                          border: "1px solid var(--primary)",
                        }}
                      >
                        Cooling System Service
                      </Badge>
                      <Badge
                        color="primary"
                        className="rounded bg-primary-ultralight mr-3 mb-3"
                        style={{
                          fontSize: 14,
                          color: "var(--dark)",
                          border: "1px solid var(--primary)",
                        }}
                      >
                        Air Conditioning Service
                      </Badge>
                      <Badge
                        color="primary"
                        className="rounded bg-primary-ultralight mr-3 mb-3"
                        style={{
                          fontSize: 14,
                          color: "var(--dark)",
                          border: "1px solid var(--primary)",
                        }}
                      >
                        Driveline Service
                      </Badge>
                      <Badge
                        color="primary"
                        className="rounded bg-primary-ultralight mr-3 mb-3"
                        style={{
                          fontSize: 14,
                          color: "var(--dark)",
                          border: "1px solid var(--primary)",
                        }}
                      >
                        Fuel/Air Induction Cleanup
                      </Badge>
                      <Badge
                        color="primary"
                        className="rounded bg-primary-ultralight mr-3 mb-3"
                        style={{
                          fontSize: 14,
                          color: "var(--dark)",
                          border: "1px solid var(--primary)",
                        }}
                      >
                        Power Steering Flush
                      </Badge>
                      <Badge
                        color="primary"
                        className="rounded bg-primary-ultralight mr-3 mb-3"
                        style={{
                          fontSize: 14,
                          color: "var(--dark)",
                          border: "1px solid var(--primary)",
                        }}
                      >
                        Transmission Service
                      </Badge>
                      <Badge
                        color="primary"
                        className="rounded bg-primary-ultralight mr-3 mb-3"
                        style={{
                          fontSize: 14,
                          color: "var(--dark)",
                          border: "1px solid var(--primary)",
                        }}
                      >
                        Vehicle Diagnosis
                      </Badge>
                      <Badge
                        color="primary"
                        className="rounded bg-primary-ultralight mr-3 mb-3"
                        style={{
                          fontSize: 14,
                          color: "var(--dark)",
                          border: "1px solid var(--primary)",
                        }}
                      >
                        Multi-Point & NYS Inspections
                      </Badge>
                      <Badge
                        color=""
                        className="rounded mr-3 mb-3 bg-white border-0 text-"
                        style={{
                          fontSize: 14,
                          color: "var(--dark)",
                          border: "1px solid var(--primary)",
                        }}
                      >
                        + more specialized services
                      </Badge>
                    </div>
                    {/**<div className="mt-6">
                      <h1 className="text-dark mb-4 display-4" id="brands">
                        Servicing All Major Brands
                      </h1>
                    </div>
                    <div style={{ overflowX: "hidden" }} className="pt-2">
                      <Row>
                        <Col
                          xs="6"
                          sm="auto"
                          className="mb-4 text-center text-sm-left"
                        >
                          <img
                            src={squatch}
                            style={{ height: 55 }}
                            alt=""
                          ></img>
                        </Col>

                        <Col
                          xs="6"
                          sm="auto"
                          className="mb-4 text-center text-sm-left"
                        >
                          <img
                            src={require("../assets/img/daves/firstmedia_logo.png")}
                            style={{ height: 55, maxWidth: "100%" }}
                            alt=""
                          ></img>
                        </Col>
                        <Col
                          xs="6"
                          sm="auto"
                          className="mb-4 text-center text-sm-left"
                        >
                          <img
                            src={hellofresh}
                            style={{ height: 55 }}
                            alt=""
                          ></img>
                        </Col>
                        <Col
                          xs="6"
                          sm="auto"
                          className="mb-4 text-center text-sm-left"
                        >
                          <img
                            src={everyplate}
                            style={{ height: 55 }}
                            alt=""
                          ></img>
                        </Col>

                        <Col
                          xs="6"
                          sm="auto"
                          className="mb-4 text-center text-sm-left"
                        >
                          <img
                            src={greenchef}
                            style={{ height: 55 }}
                            alt=""
                          ></img>
                        </Col>
                        <Col
                          xs="6"
                          sm="auto"
                          className="mb-4 text-center text-sm-left"
                        >
                          <img
                            src={require("../assets/img/daves/chefsplate_logo.png")}
                            style={{ height: 55 }}
                            alt=""
                          ></img>
                        </Col>
                        <Col
                          xs="6"
                          sm="auto"
                          className="mb-4 text-center text-sm-left"
                        >
                          <img
                            src={require("../assets/img/daves/caraway_logo.png")}
                            style={{ height: 55 }}
                            alt=""
                          ></img>
                        </Col>
                        <Col
                          xs="6"
                          sm="auto"
                          className="mb-4 text-center text-sm-left"
                        >
                          <img
                            src={require("../assets/img/daves/abc_logo.png")}
                            style={{ height: 55 }}
                            alt=""
                            height="55"
                            width="55"
                          ></img>
                        </Col>
                        <Col
                          xs="6"
                          sm="auto"
                          className="mb-4 text-center text-sm-left"
                        >
                          <img
                            src={require("../assets/img/daves/hgtv_logo.png")}
                            style={{ height: 55 }}
                            alt=""
                          ></img>
                        </Col>
                        <Col
                          xs="6"
                          sm="auto"
                          className="mb-4 text-center text-sm-left"
                        >
                          <img
                            src={require("../assets/img/daves/pbs_logo.png")}
                            style={{ height: 55 }}
                            alt=""
                          ></img>
                        </Col>
                        <Col
                          xs="6"
                          sm="auto"
                          className="mb-4 text-center text-sm-left"
                        >
                          <img
                            src={require("../assets/img/daves/npr_logo.png")}
                            style={{ height: 55 }}
                            alt=""
                          ></img>
                        </Col>
                        <Col
                          xs="6"
                          sm="auto"
                          className="mb-4 text-center text-sm-left"
                        >
                          <img
                            src={require("../assets/img/daves/wegmans_logo.png")}
                            style={{ height: 55 }}
                            alt=""
                          ></img>
                        </Col>
                      </Row>
                    </div> */}
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
        </div>
        <div
          className="bg-superlight mb-5 pb-3"
          style={{
            borderTop: "1px solid var(--lighter)",
            borderBottom: "1px solid var(--lighter)",
          }}
        >
          <div style={{ maxWidth: 1100, margin: "auto" }}>
            <div className="px-1 px-md-4">
              <Container fluid>
                <div className="py-5">
                  <Row className="align-items-center">
                    <Col xs="12" md="">
                      <h1
                        className="text-dark mt-0 mt-md-2 mt-lg-0 mb-2 display-4"
                        id="portfolio"
                      >
                        Customers Love <br className="d-block d-lg-none"></br>{" "}
                        "The Dave's Difference"
                      </h1>
                      <p className="text-medium mb-0 font-weight-400">
                        Hear why hundreds of customers trust us with all of
                        their automotive needs.
                      </p>
                    </Col>
                    <Col
                      xs="12"
                      md="auto"
                      className="text-left mt-3 mt-md-0 text-md-right"
                    >
                      <a
                        href="https://goo.gl/maps/SnXGG6f2R98kY1tr6"
                        target="_blank"
                      >
                        <img
                          src={googleReviews}
                          className="shadow-sm"
                          style={{ height: 55 }}
                          alt="Google Reviews Logo"
                        />
                      </a>
                    </Col>
                  </Row>
                  <div className="rounded bg-white px-4 py-4 px-lg-5 py-lg-5 border shadow mt-4 mt-md-5">
                    <h1 className="text-dark font-weight-500 py-2 review-text">
                      "{this.state.review?.review}"
                    </h1>

                    <div className="mt-3">
                      <Row className="align-items-center">
                        <Col xs="auto">
                          <img
                            src={this.state.review?.image}
                            style={{ height: 50 }}
                            alt="Reviewer Image"
                            height="50"
                            width="50"
                          ></img>
                        </Col>
                        <Col xs="" className="pl-1">
                          <h2 className="text-dark mb-0">
                            {this.state.review?.name}
                          </h2>
                          <p className="small text-muted mb-0">
                            Dave's Automotive Customer
                          </p>
                        </Col>
                        <Col xs="auto">
                          <img
                            src={require("../assets/img/daves/google_g_icon_download.png")}
                            style={{ height: 35 }}
                            alt="Google Image"
                            height="35"
                            width="35"
                          ></img>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div className="mt-4 d-flex justify-content-center">
                    <Row className="align-items-center">
                      <Col xs="auto">
                        <Button
                          className="btn-icon-only"
                          color="primary"
                          onClick={() => {
                            this.setPortfolioIndex(-1);
                          }}
                        >
                          <svg viewBox="0 0 24 24" fill="#FFFFFF">
                            <path d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z" />
                          </svg>
                        </Button>
                      </Col>
                      <Col xs="auto">
                        {this.state.portfolioIndex + 1} of{" "}
                        {this.state.reviews?.length}
                      </Col>
                      <Col xs="auto">
                        <Button
                          className="btn-icon-only"
                          color="primary"
                          onClick={() => {
                            this.setPortfolioIndex(1);
                          }}
                        >
                          <svg viewBox="0 0 24 24" fill="#FFFFFF">
                            <path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
                          </svg>
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Container>
            </div>
          </div>
        </div>
        <div id="fleet"></div>
        <div className="bg-white mb-5 pb-3">
          <div style={{ maxWidth: 1100, margin: "auto" }}>
            <div className="px-1 px-md-4">
              <Container fluid>
                <div className="py-5">
                  <Row className="align-items-center justify-content-center">
                    <Col xs="12" md="8">
                      <h1
                        className="text-dark mt-0 mt-md-2 mt-lg-0 mb-2 display-4"
                        id="portfolio"
                      >
                        Servicing Large &amp; Small Fleet Accounts
                      </h1>
                      <p className="text-medium mb-0 font-weight-400">
                        We strive to add convenience and quality to your
                        company's service needs. With our Fleet Account Program
                        you can expect priority treatment and our special Fleet
                        Labor Pricing.
                      </p>

                      <Row className="mt-4">
                        <Col
                          xs="6"
                          sm="auto"
                          className="mb-4 text-center text-sm-left"
                        >
                          <img
                            src={require("../assets/img/daves/fedex_logo.png")}
                            style={{ height: 40 }}
                            alt=""
                          ></img>
                        </Col>

                        <Col
                          xs="6"
                          sm="auto"
                          className="mb-4 text-center text-sm-left"
                        >
                          <img
                            src={require("../assets/img/daves/national_fuel_logo.png")}
                            style={{ height: 40, maxWidth: "100%" }}
                            alt=""
                          ></img>
                        </Col>
                      </Row>
                    </Col>
                    <Col
                      xs="8"
                      md="4"
                      className="text-left mt-3 mt-md-0 text-md-right"
                    >
                      <div
                        className="rounded shadow"
                        style={{ overflow: "hidden" }}
                      >
                        <img
                          src={require("../assets/img/daves/fleet_account.png")}
                          className="shadow-sm"
                          style={{ width: "100%" }}
                          alt="Google Reviews Logo"
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Container>
            </div>
          </div>
        </div>
        <div id="contact"></div>
        <div className="" style={{ maxWidth: 1100, margin: "auto" }}>
          <div className=" px-1 px-md-4">
            <Container fluid>
              <div>
                {" "}
                {/**className="mt--7" */}
                <div className="rounded border bg-white shadow px-4 py-4">
                  <Row style={{ display: "flex", flexWrap: "wrap" }}>
                    <Col xs="12" sm="12" md="6">
                      <h1 className="text-dark mt-0 display-4 mb-4">
                        Visit Us For Service
                      </h1>
                      <h3 className="mb-1 font-weight-500">
                        <a
                          className="text-muted"
                          target="_blank"
                          href="https://www.google.com/maps?ll=42.761532,-78.752746&z=17&t=m&hl=en&gl=US&mapclient=embed&cid=4861963968256125185"
                        >
                          227 Thorn Ave.
                        </a>
                      </h3>
                      <h3 className="text-muted mb-2 font-weight-500">
                        <a
                          className="text-muted"
                          target="_blank"
                          href="https://www.google.com/maps?ll=42.761532,-78.752746&z=17&t=m&hl=en&gl=US&mapclient=embed&cid=4861963968256125185"
                        >
                          Orchard Park, NY 14127
                        </a>
                      </h3>
                      <h3 className="text-muted mb-0 font-weight-500">
                        <a href="tel:(716) 662-1467">(716) 662-1467</a>
                      </h3>
                      <a href="tel:+17166621467">
                        <Button className="mt-4" size="" color="primary">
                          Schedule Appointment
                        </Button>
                      </a>
                      <hr className="my-4"></hr>
                      <h3 className="font-weight-bold text-dark">
                        BUSINESS HOURS
                      </h3>
                      <table>
                        <tbody>
                          <tr>
                            <td className="pr-4">
                              <p className="mb-0">
                                <span className="font-weight-500 text-dark">
                                  Monday
                                </span>
                              </p>
                            </td>
                            <td>
                              <p className="mb-0">8:00 AM - 5:00 PM</p>
                            </td>
                          </tr>
                          <tr>
                            <td className="pr-4">
                              <p className="mb-0">
                                <span className="font-weight-500 text-dark">
                                  Tuesday
                                </span>
                              </p>
                            </td>
                            <td>
                              <p className="mb-0">8:00 AM - 5:00 PM</p>
                            </td>
                          </tr>
                          <tr>
                            <td className="pr-4">
                              <p className="mb-0">
                                <span className="font-weight-500 text-dark">
                                  Wednesday
                                </span>
                              </p>
                            </td>
                            <td>
                              <p className="mb-0">8:00 AM - 5:00 PM</p>
                            </td>
                          </tr>
                          <tr>
                            <td className="pr-4">
                              <p className="mb-0">
                                <span className="font-weight-500 text-dark">
                                  Thursday
                                </span>
                              </p>
                            </td>
                            <td>
                              <p className="mb-0">8:00 AM - 5:00 PM</p>
                            </td>
                          </tr>
                          <tr>
                            <td className="pr-4">
                              <p className="mb-0">
                                <span className="font-weight-500 text-dark">
                                  Friday
                                </span>
                              </p>
                            </td>
                            <td>
                              <p className="mb-0">8:00 AM - 4:00 PM</p>
                            </td>
                          </tr>
                          <tr>
                            <td className="pr-4">
                              <p className="mb-0">
                                <span className="font-weight-500 text-dark">
                                  Saturday
                                </span>
                              </p>
                            </td>
                            <td>
                              <p className="mb-0">Closed</p>
                            </td>
                          </tr>
                          <tr>
                            <td className="pr-4">
                              <p className="mb-0">
                                <span className="font-weight-500 text-dark">
                                  Sunday
                                </span>
                              </p>
                            </td>
                            <td>
                              <p className="mb-0">Closed</p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Col>
                    <Col xs="12" sm="12" md="6" className="mt-3 mt-md-0">
                      <div
                        style={{
                          overflow: "hidden",
                        }}
                        className="rounded border h-100"
                      >
                        <iframe
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1464.6232268929398!2d-78.75264384446993!3d42.76199280595027!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d3051f4d4abd91%3A0x43792a7826a7a901!2sDave&#39;s%20Automotive!5e0!3m2!1sen!2sus!4v1677117484935!5m2!1sen!2sus"
                          style={{
                            width: "100%",
                            height: "100%",
                            minHeight: 250,
                            border: 0,
                          }}
                          allowfullscreen=""
                          loading="lazy"
                          referrerpolicy="no-referrer-when-downgrade"
                        ></iframe>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
              <div id="about"></div>
              <div className="mt-6">
                <div>
                  <h1
                    className="text-dark mt-0 mt-md-2 mt-lg-0 mb-2 display-4"
                    id="portfolio"
                  >
                    Our Company
                  </h1>
                  <div>
                    <h3 className="text-primary mb-1">
                      A History Of Quality Service &amp; Affordable Prices Since
                      1984
                    </h3>
                  </div>
                  <div>
                    <Row className="align-items-center mt-5">
                      <Col xs="12" sm="8">
                        <p>
                          After repairing family and friends vehicles outdoors
                          since his teen years, the first real shop was opened
                          at 4169 North Buffalo Street in Orchard Park (now NOCO
                          Express). This location, a three bay, closed down
                          Sunoco gas station was a scary first step as a other,
                          established repair shops were across the street and
                          just down the road.
                        </p>
                        <p className="mb-0">
                          In time, Dave’s small, honest garage grew. More and
                          more people became loyal customers along with their
                          friends and family members.
                        </p>
                      </Col>
                      <Col xs="12" sm="4" className="mt-4 mt-sm-0">
                        <div
                          className="px-2 pt-2 pb-5 border shadow rounded"
                          style={{ overflow: "hidden" }}
                        >
                          <img
                            src={require("../assets/img/daves/1985.jpg")}
                            alt="1985 shop"
                            style={{ width: "100%" }}
                          ></img>
                        </div>
                      </Col>
                    </Row>
                    <Row className="align-items-center mt-5">
                      <Col xs="12" sm="8" className="order-sm-2">
                        <p>
                          By 1990, more room was needed. A closed auto
                          dealership (Carrow Chevrolet) at 6185 West Quaker Road
                          became available. With 8 service bays this seemed to
                          be ,”just the ticket”, and not too far from the
                          growing customer base that we had enjoyed.
                        </p>
                        <p>
                          Once again, business continued to grow with more and
                          more general automotive customers and an increasing
                          number of fleet truck accounts. Again, we needed more
                          room.
                        </p>
                      </Col>
                      <Col xs="12" sm="4" className="mt-4 mt-sm-0">
                        <div
                          className="px-2 pt-2 pb-5 border shadow rounded"
                          style={{ overflow: "hidden" }}
                        >
                          <img
                            src={require("../assets/img/daves/1990.jpg")}
                            alt="1985 shop"
                            style={{ width: "100%" }}
                          ></img>
                        </div>
                      </Col>
                    </Row>
                    <Row className="align-items-center mt-5">
                      <Col xs="12" sm="8">
                        <p>
                          The larger required service area was found at the
                          Southtowns Business Center just outside the village.
                          Ten service bays, six electric hoists, two fabrication
                          bays and a comfortable waiting room became Dave’s
                          Automotive’s permanent home!
                        </p>
                        <p>
                          Always remembering where we came from and how we
                          started reminds us how valuable each customer is and
                          we promise to service every one of you with honesty,
                          fairness and respect. Thank you for your patronage.
                        </p>
                      </Col>
                      <Col xs="12" sm="4" className="mt-4 mt-sm-0">
                        <div
                          className="px-2 pt-2 pb-5 border shadow rounded"
                          style={{ overflow: "hidden" }}
                        >
                          <img
                            src={require("../assets/img/daves/dave3-1.jpg")}
                            alt="1985 shop"
                            style={{ width: "100%" }}
                          ></img>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>

              <div className="mt-5 mb-5" id="contact">
                <div className="bg-primary px-4 py-4 rounded shadow">
                  <Row className="align-items-center">
                    <Col xs="12" sm="">
                      <h1 className="text-white mt-0  mb-1 display-4">
                        Contact Us
                      </h1>
                      <p className="text-white mb-0 mt-0 font-weight-400">
                        In need of vehicle repair &amp; maintenance - feel free
                        to reach out!
                      </p>
                    </Col>
                    <Col xs="12" sm="auto">
                      <a href="mailto:+17166621467" target="_blank">
                        <Button
                          block
                          color="white"
                          className="text-primary px-3 py-2 mt-3 mt-sm-0"
                          style={{ fontSize: 18 }}
                        >
                          CALL DAVE'S
                        </Button>
                      </a>
                    </Col>
                  </Row>
                </div>
                <div></div>
              </div>
              <div className="mt-5 text-medium small mb-5">
                &copy; Dave's Automotive. All Rights Reserved.
              </div>
            </Container>
          </div>
        </div>
      </>
    );
  }
}

export default IndexPage;
