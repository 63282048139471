/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import ArrowText from "../ArrowText";
import React from "react";
import { Link, animateScroll as scroll } from "react-scroll";
// reactstrap components
import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  DropdownItem,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  Button,
} from "reactstrap";

let logo = require("../../assets/img/daves/daves_auto_logo.svg");

class PrimaryNavbar extends React.Component {
  collapse() {
    if (window.innerWidth >= 992) {
      return;
    }

    let collapse = document.getElementById("navbar-collapse-main");
    let collapseContainer = document.getElementsByClassName("navbar-collapse");

    console.log(collapseContainer[0]?.className);

    if (
      collapse &&
      collapseContainer?.length &&
      collapseContainer[0]?.className?.includes("show")
    ) {
      collapse.click();
    }
  }

  render() {
    return (
      <>
        <Navbar
          className="navbar-top fixed-top navbar-horizontal navbar-light bg-white start"
          expand="lg"
        >
          <Container
            fluid
            style={{
              width: "100%",
              maxWidth: 1100,
              margin: "auto",
              position: "relative",
            }}
            className="px-lg-5"
          >
            <button
              className="navbar-toggler pr-0 pl-0"
              id="navbar-collapse-main"
              aria-label="menu toggle button"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <Link
              className="nav-link py-2"
              to="#home"
              aria-label="Home"
              spy={true}
              smooth={true}
              offset={-100}
              duration={300}
            >
              <img
                src={logo}
                alt="Dave's Auto logo"
                className="daves-logo"
              ></img>
            </Link>

            <UncontrolledCollapse
              className="navbar-items"
              navbar
              toggler="#navbar-collapse-main"
            >
              <div className="navbar-collapse-header d-md-none">
                <Row className="align-items-end">
                  <Col className="collapse-close" xs="12">
                    <button
                      className="navbar-toggler"
                      id="navbar-collapse-main-2"
                    >
                      <span />
                      <span />
                    </button>
                  </Col>
                </Row>
              </div>
              <Nav navbar className="">
                <NavItem>
                  <Link
                    className="nav-link px-lg-2 cursor-pointer"
                    to="home"
                    onClick={this.collapse.bind(this)}
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={300}
                  >
                    <span className="nav-link-inner--text text-dark text-uppercase font-weight-700">
                      Home
                    </span>
                  </Link>
                </NavItem>
                <NavItem>
                  <Link
                    className="nav-link px-lg-2 cursor-pointer"
                    to="autorepair"
                    onClick={this.collapse.bind(this)}
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={300}
                  >
                    <span className="nav-link-inner--text text-dark text-uppercase font-weight-700">
                      Auto Repair
                    </span>
                  </Link>
                </NavItem>

                <NavItem>
                  <Link
                    className="nav-link px-lg-2 cursor-pointer"
                    to="fleet"
                    onClick={this.collapse.bind(this)}
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={300}
                  >
                    <span className="nav-link-inner--text text-dark text-uppercase font-weight-700">
                      Fleet Accounts
                    </span>
                  </Link>
                </NavItem>

                <NavItem>
                  <Link
                    className="nav-link px-lg-2 cursor-pointer"
                    to="contact"
                    onClick={this.collapse.bind(this)}
                    spy={true}
                    smooth={true}
                    offset={-150}
                    duration={300}
                  >
                    <span className="nav-link-inner--text text-dark text-uppercase font-weight-700">
                      Contact
                    </span>
                  </Link>
                </NavItem>
                <NavItem className="">
                  <Link
                    className="nav-link px-lg-2 cursor-pointer"
                    to="about"
                    onClick={this.collapse.bind(this)}
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={300}
                  >
                    <span className="nav-link-inner--text text-dark text-uppercase font-weight-700">
                      Company
                    </span>
                  </Link>
                </NavItem>
              </Nav>
              <div
                className="d-none d-lg-block text-right"
                style={{ position: "absolute", right: "0rem" }}
              >
                <div>
                  <a
                    href="tel:+17166621467"
                    target="_blank"
                    className="font-weight-700"
                  >
                    (716) 662-1467
                  </a>
                </div>
                <div>
                  <a
                    href="https://www.google.com/maps/place/Dave's+Automotive/@42.7615492,-78.7537865,18.09z/data=!4m6!3m5!1s0x89d3051f4d4abd91:0x43792a7826a7a901!8m2!3d42.7615318!4d-78.7527465!16s%2Fg%2F11h2hmz0xj"
                    target="_blank"
                    style={{ fontSize: 13, lineHeight: 1.2 }}
                    className="text-medium"
                  >
                    227 Thorn Ave.,
                    <div
                      className="d-none d-lg-block d-xl-none"
                      style={{ marginTop: -5 }}
                    ></div>
                    &nbsp;Orchard Park, NY 14127
                  </a>
                </div>
              </div>
            </UncontrolledCollapse>

            <div
              className="d-block d-lg-none text-right"
              style={{ position: "fixed", right: "1rem", top: "1rem" }}
            >
              <div>
                <a
                  href="https://www.google.com/maps/place/Dave's+Automotive/@42.7615492,-78.7537865,18.09z/data=!4m6!3m5!1s0x89d3051f4d4abd91:0x43792a7826a7a901!8m2!3d42.7615318!4d-78.7527465!16s%2Fg%2F11h2hmz0xj"
                  target="_blank"
                  className="font-weight-700 mr-3"
                >
                  <Button
                    size="sm"
                    outline={true}
                    color="dark"
                    className="btn-icon-only border-0 bg-white text-dark"
                    style={{ boxShadow: "none", transform: "none" }}
                  >
                    <svg viewBox="0 0 24 24">
                      <path d="M12,2C15.31,2 18,4.66 18,7.95C18,12.41 12,19 12,19C12,19 6,12.41 6,7.95C6,4.66 8.69,2 12,2M12,6A2,2 0 0,0 10,8A2,2 0 0,0 12,10A2,2 0 0,0 14,8A2,2 0 0,0 12,6M20,19C20,21.21 16.42,23 12,23C7.58,23 4,21.21 4,19C4,17.71 5.22,16.56 7.11,15.83L7.75,16.74C6.67,17.19 6,17.81 6,18.5C6,19.88 8.69,21 12,21C15.31,21 18,19.88 18,18.5C18,17.81 17.33,17.19 16.25,16.74L16.89,15.83C18.78,16.56 20,17.71 20,19Z" />
                    </svg>
                  </Button>
                </a>
                <a
                  href="tel:+17166621467"
                  target="_blank"
                  className="font-weight-700"
                >
                  <Button
                    size="sm"
                    outline={true}
                    color="dark"
                    className="btn-icon-only border-0 bg-white text-dark"
                    style={{ boxShadow: "none", transform: "none" }}
                  >
                    <svg viewBox="0 0 24 24">
                      <path d="M6.62,10.79C8.06,13.62 10.38,15.94 13.21,17.38L15.41,15.18C15.69,14.9 16.08,14.82 16.43,14.93C17.55,15.3 18.75,15.5 20,15.5A1,1 0 0,1 21,16.5V20A1,1 0 0,1 20,21A17,17 0 0,1 3,4A1,1 0 0,1 4,3H7.5A1,1 0 0,1 8.5,4C8.5,5.25 8.7,6.45 9.07,7.57C9.18,7.92 9.1,8.31 8.82,8.59L6.62,10.79Z" />
                    </svg>
                  </Button>
                </a>
              </div>
            </div>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default PrimaryNavbar;
